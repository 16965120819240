import React, { useEffect, useState } from 'react';
import axios from 'axios';

const specialtyOptions = [
  "Plumbing Supplies",
  "Electrical Supplies",
  "HVAC (Heating, Ventilation, and Air Conditioning)",
  "Carpentry and Woodwork",
  "Roofing Materials",
  "Flooring Materials",
  "Paint and Finishes",
  "Hardware and Fasteners",
  "Tools and Equipment",
  "Insulation and Weatherproofing",
  "Appliances and Fixtures",
  "Outdoor and Landscaping",
  "Concrete and Masonry",
  "Adhesives and Sealants",
  "Miscellaneous Supplies",
];

const Repair = ({ apiBaseUrl }) => {
  const [vendors, setVendors] = useState({});
  const [isAdding, setIsAdding] = useState(false);
  const [newVendor, setNewVendor] = useState({
    name: '',
    address: '',
    description: '',
    email: '',
    notes: '',
    phoneNumber: '',
    specialty: [],
  });
  const [customSpecialty, setCustomSpecialty] = useState('');
  const [expandedVendor, setExpandedVendor] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editVendorData, setEditVendorData] = useState(null);

  useEffect(() => {
    // Fetch the data from the URL
    fetch(`${apiBaseUrl}/preferred_vendors`)
      .then((response) => response.json())
      .then((data) => setVendors(data))
      .catch((error) => console.error('Error fetching vendors:', error));
  }, []);

  const handleAddVendor = () => {
    setIsAdding(true);
    setIsEditing(false);
    setExpandedVendor(null);
  };

  const handleInputChange = (e, isEdit = false) => {
    const { name, value } = e.target;
    if (isEdit) {
      setEditVendorData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setNewVendor((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSpecialtyChange = (e, isEdit = false) => {
    const { value, checked } = e.target;
    if (isEdit) {
      setEditVendorData((prev) => {
        if (checked) {
          if (!prev.specialty.includes(value)) {
            return { ...prev, specialty: [...prev.specialty, value] };
          }
          return prev;
        } else {
          return {
            ...prev,
            specialty: prev.specialty.filter((item) => item !== value),
          };
        }
      });
    } else {
      setNewVendor((prev) => {
        if (checked) {
          if (!prev.specialty.includes(value)) {
            return { ...prev, specialty: [...prev.specialty, value] };
          }
          return prev;
        } else {
          return {
            ...prev,
            specialty: prev.specialty.filter((item) => item !== value),
          };
        }
      });
    }
  };

  const handleAddCustomSpecialty = (isEdit = false) => {
    const trimmedSpecialty = customSpecialty.trim();
    if (trimmedSpecialty) {
      if (isEdit) {
        if (!editVendorData.specialty.includes(trimmedSpecialty)) {
          setEditVendorData((prev) => ({
            ...prev,
            specialty: [...prev.specialty, trimmedSpecialty],
          }));
        }
      } else {
        if (!newVendor.specialty.includes(trimmedSpecialty)) {
          setNewVendor((prev) => ({
            ...prev,
            specialty: [...prev.specialty, trimmedSpecialty],
          }));
        }
      }
      setCustomSpecialty('');
    }
  };

  const handleRemoveCustomSpecialty = (specialty, isEdit = false) => {
    if (isEdit) {
      setEditVendorData((prev) => ({
        ...prev,
        specialty: prev.specialty.filter((item) => item !== specialty),
      }));
    } else {
      setNewVendor((prev) => ({
        ...prev,
        specialty: prev.specialty.filter((item) => item !== specialty),
      }));
    }
  };

  const handleDone = async (isEdit = false) => {
    const vendorData = isEdit ? editVendorData : newVendor;

    if (!vendorData.name.trim()) {
      alert('Vendor name is required.');
      return;
    }

    const payload = {
      Name: vendorData.name,
      updated_info: {
        specialty: vendorData.specialty,
        email: vendorData.email,
        "phone number": vendorData.phoneNumber,
        notes: vendorData.notes,
        address: vendorData.address,
        description: vendorData.description,
      },
    };

    try {
      const response = await axios.post(`${apiBaseUrl}/preferred_vendors`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Success:', response.data);
      alert('Vendor submitted successfully!');

      // Update local vendors state with the new vendor
      setVendors((prevVendors) => ({
        ...prevVendors,
        [vendorData.name]: payload.updated_info,
      }));

      // Reset the form
      if (isEdit) {
        setIsEditing(false);
        setEditVendorData(null);
        setExpandedVendor(null);
      } else {
        setIsAdding(false);
        setNewVendor({
          name: '',
          address: '',
          description: '',
          email: '',
          notes: '',
          phoneNumber: '',
          specialty: [],
        });
        setCustomSpecialty('');
      }
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      alert(`Failed to submit vendor. Please try again.\nError: ${error.message}`);
    }
  };

  const handleCancel = (isEdit = false) => {
    if (isEdit) {
      setIsEditing(false);
      setEditVendorData(null);
    } else {
      setIsAdding(false);
      setNewVendor({
        name: '',
        address: '',
        description: '',
        email: '',
        notes: '',
        phoneNumber: '',
        specialty: [],
      });
      setCustomSpecialty('');
    }
  };

  const handleVendorClick = (vendorName) => {
    if (expandedVendor === vendorName) {
      setExpandedVendor(null);
    } else {
      setExpandedVendor(vendorName);
      setIsEditing(false);
    }
  };

  const handleEditClick = (vendorName) => {
    setIsEditing(true);
    setExpandedVendor(vendorName);
    const vendorInfo = vendors[vendorName];
    setEditVendorData({
      name: vendorName,
      address: vendorInfo.address || '',
      description: vendorInfo.description || '',
      email: vendorInfo.email || '',
      notes: vendorInfo.notes || '',
      phoneNumber: vendorInfo['phone number'] || '',
      specialty: vendorInfo.specialty || [],
    });
    setCustomSpecialty('');
  };

  return (
    <div className="container mx-auto p-5">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Preferred Vendors</h1>
      </div>

      {/* List of Vendors */}
      <div className="space-y-6">
        {Object.keys(vendors).map((vendor) => (
          <div
            key={vendor}
            className="border rounded-lg shadow p-6 bg-white cursor-pointer"
          >
            {/* Vendor Header */}
            <div onClick={() => handleVendorClick(vendor)}>
              <h2 className="text-2xl font-semibold mb-2">{vendor}</h2>
              <p className="mb-1">
                <strong>Specialty:</strong> {vendors[vendor]?.specialty.join(', ')}
              </p>
              <p className="mb-1">
                <strong>Notes:</strong> {vendors[vendor]?.notes}
              </p>
            </div>

            {/* Expanded Vendor Details */}
            {expandedVendor === vendor && !isEditing && (
              <>
                <p className="mb-1">
                  <strong>Email:</strong>{' '}
                  <a
                    href={`mailto:${vendors[vendor]?.email}`}
                    className="text-blue-500 underline"
                  >
                    {vendors[vendor]?.email}
                  </a>
                </p>
                <p className="mb-1">
                  <strong>Phone Number:</strong>{' '}
                  <a
                    href={`tel:${vendors[vendor]?.['phone number']}`}
                    className="text-blue-500 underline"
                  >
                    {vendors[vendor]?.['phone number']}
                  </a>
                </p>
                <p className="mb-1">
                  <strong>Address:</strong> {vendors[vendor]?.address}
                </p>
                <p className="mb-1">
                  <strong>Description:</strong> {vendors[vendor]?.description}
                </p>
                {/* Edit Button */}
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => handleEditClick(vendor)}
                    className="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 transition duration-300"
                  >
                    Edit
                  </button>
                </div>
              </>
            )}

            {/* Edit Form */}
            {expandedVendor === vendor && isEditing && (
              <div className="mt-4">
                {/* Edit Form Fields */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {/* Vendor Name */}
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Vendor Name{' '}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={editVendorData.name}
                      onChange={(e) => handleInputChange(e, true)}
                      className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter vendor name"
                      required
                    />
                  </div>

                  {/* Email */}
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={editVendorData.email}
                      onChange={(e) => handleInputChange(e, true)}
                      className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter vendor email"
                    />
                  </div>

                  {/* Phone Number */}
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={editVendorData.phoneNumber}
                      onChange={(e) => handleInputChange(e, true)}
                      className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter vendor phone number"
                    />
                  </div>

                  {/* Address */}
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Address
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={editVendorData.address}
                      onChange={(e) => handleInputChange(e, true)}
                      className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter vendor address"
                    />
                  </div>
                </div>

                {/* Description */}
                <div className="mt-4">
                  <label className="block text-sm font-medium mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    value={editVendorData.description}
                    onChange={(e) => handleInputChange(e, true)}
                    className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter vendor description"
                    rows="4"
                  />
                </div>

                {/* Notes */}
                <div className="mt-4">
                  <label className="block text-sm font-medium mb-1">
                    Notes
                  </label>
                  <textarea
                    name="notes"
                    value={editVendorData.notes}
                    onChange={(e) => handleInputChange(e, true)}
                    className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter vendor notes"
                    rows="2"
                  />
                </div>

                {/* Specialty */}
                <div className="mt-4">
                  <label className="block text-sm font-medium mb-2">
                    Specialty
                  </label>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2 max-h-60 overflow-y-auto">
                    {specialtyOptions.map((specialty) => (
                      <label key={specialty} className="flex items-center">
                        <input
                          type="checkbox"
                          value={specialty}
                          checked={editVendorData.specialty.includes(specialty)}
                          onChange={(e) => handleSpecialtyChange(e, true)}
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                        <span className="ml-2 text-gray-700">{specialty}</span>
                      </label>
                    ))}
                  </div>

                  {/* Add Custom Specialty */}
                  <div className="mt-4 flex items-center">
                    <input
                      type="text"
                      value={customSpecialty}
                      onChange={(e) => setCustomSpecialty(e.target.value)}
                      className="flex-1 border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Add a custom specialty"
                    />
                    <button
                      onClick={() => handleAddCustomSpecialty(true)}
                      className="ml-2 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
                    >
                      Add
                    </button>
                  </div>

                  {/* Display Added Custom Specialties */}
                  {editVendorData.specialty.some(
                    (s) => !specialtyOptions.includes(s)
                  ) && (
                    <div className="mt-4">
                      <h3 className="text-sm font-medium mb-2">
                        Custom Specialties:
                      </h3>
                      <div className="flex flex-wrap gap-2">
                        {editVendorData.specialty
                          .filter((s) => !specialtyOptions.includes(s))
                          .map((specialty) => (
                            <div
                              key={specialty}
                              className="flex items-center bg-gray-200 text-gray-700 px-3 py-1 rounded-full"
                            >
                              <span>{specialty}</span>
                              <button
                                onClick={() =>
                                  handleRemoveCustomSpecialty(specialty, true)
                                }
                                className="ml-2 text-red-500 hover:text-red-700"
                                title="Remove specialty"
                              >
                                &times;
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>

                {/* Form Actions */}
                <div className="flex justify-end mt-6 space-x-4">
                  <button
                    onClick={() => handleCancel(true)}
                    className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 transition duration-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleDone(true)}
                    className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-300"
                  >
                    Done
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Add Vendor Button */}
      {!isAdding && (
        <div className="flex justify-center mt-8">
          <button
            onClick={handleAddVendor}
            className="bg-blue-600 text-white py-2 px-6 rounded hover:bg-blue-700 transition duration-300"
          >
            Add Vendor
          </button>
        </div>
      )}

      {/* Add Vendor Form */}
      {isAdding && (
        <div className="mt-8 border rounded-lg shadow p-6 bg-gray-50">
          <h2 className="text-2xl font-semibold mb-4">Add New Vendor</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Vendor Name */}
            <div>
              <label className="block text-sm font-medium mb-1">
                Vendor Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={newVendor.name}
                onChange={handleInputChange}
                className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter vendor name"
                required
              />
            </div>

            {/* Email */}
            <div>
              <label className="block text-sm font-medium mb-1">Email</label>
              <input
                type="email"
                name="email"
                value={newVendor.email}
                onChange={handleInputChange}
                className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter vendor email"
              />
            </div>

            {/* Phone Number */}
            <div>
              <label className="block text-sm font-medium mb-1">
                Phone Number
              </label>
              <input
                type="text"
                name="phoneNumber"
                value={newVendor.phoneNumber}
                onChange={handleInputChange}
                className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter vendor phone number"
              />
            </div>

            {/* Address */}
            <div>
              <label className="block text-sm font-medium mb-1">Address</label>
              <input
                type="text"
                name="address"
                value={newVendor.address}
                onChange={handleInputChange}
                className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter vendor address"
              />
            </div>
          </div>

          {/* Description */}
          <div className="mt-4">
            <label className="block text-sm font-medium mb-1">
              Description
            </label>
            <textarea
              name="description"
              value={newVendor.description}
              onChange={handleInputChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter vendor description"
              rows="4"
            />
          </div>

          {/* Notes */}
          <div className="mt-4">
            <label className="block text-sm font-medium mb-1">Notes</label>
            <textarea
              name="notes"
              value={newVendor.notes}
              onChange={handleInputChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter vendor notes"
              rows="2"
            />
          </div>

          {/* Specialty */}
          <div className="mt-4">
            <label className="block text-sm font-medium mb-2">Specialty</label>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 max-h-60 overflow-y-auto">
              {specialtyOptions.map((specialty) => (
                <label key={specialty} className="flex items-center">
                  <input
                    type="checkbox"
                    value={specialty}
                    checked={newVendor.specialty.includes(specialty)}
                    onChange={handleSpecialtyChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2 text-gray-700">{specialty}</span>
                </label>
              ))}
            </div>

            {/* Add Custom Specialty */}
            <div className="mt-4 flex items-center">
              <input
                type="text"
                value={customSpecialty}
                onChange={(e) => setCustomSpecialty(e.target.value)}
                className="flex-1 border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Add a custom specialty"
              />
              <button
                onClick={handleAddCustomSpecialty}
                className="ml-2 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
              >
                Add
              </button>
            </div>

            {/* Display Added Custom Specialties */}
            {newVendor.specialty.some(
              (s) => !specialtyOptions.includes(s)
            ) && (
              <div className="mt-4">
                <h3 className="text-sm font-medium mb-2">
                  Custom Specialties:
                </h3>
                <div className="flex flex-wrap gap-2">
                  {newVendor.specialty
                    .filter((s) => !specialtyOptions.includes(s))
                    .map((specialty) => (
                      <div
                        key={specialty}
                        className="flex items-center bg-gray-200 text-gray-700 px-3 py-1 rounded-full"
                      >
                        <span>{specialty}</span>
                        <button
                          onClick={() =>
                            handleRemoveCustomSpecialty(specialty)
                          }
                          className="ml-2 text-red-500 hover:text-red-700"
                          title="Remove specialty"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>

          {/* Form Actions */}
          <div className="flex justify-end mt-6 space-x-4">
            <button
              onClick={handleCancel}
              className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 transition duration-300"
            >
              Cancel
            </button>
            <button
              onClick={handleDone}
              className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-300"
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Repair;
