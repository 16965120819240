// src/pages/RepairTickets.jsx
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const RepairTickets = ({ apiBaseUrl }) => {
  const [customers, setCustomers] = useState([]); // State for customers
  const [selectedCustomer, setSelectedCustomer] = useState(null); // State for selected customer
  const [customerContent, setCustomerContent] = useState(null); // State for selected customer content
  const [address, setAddress] = useState(null); // New state for address
  const [imgContent, setImgContent] = useState(null);
  const [diagnosisContent, setDiagnosisContent] = useState(null);
  const [breakdownContent, setBreakdownContent] = useState(null);
  const [contractorContent, setContractorContent] = useState(null);
  const [preferredVendorsContent, setPreferredVendorsContent] = useState(null); // New state for preferred vendors
  const [draftedEmail, setDraftedEmail] = useState(null);
  const [draftedVoiceEmail, setDraftedVoiceEmail] = useState(null);
  const [draftedVoiceNoEmail, setDraftedVoiceNoEmail] = useState(null);

  // State to track selected vendor type and task
  const [selectedVendor, setSelectedVendor] = useState({ task: null, vendorType: null });

  // State to track expanded descriptions for preferred vendors
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  // Ref to store polling interval ID
  const pollingRef = useRef(null);

  // New state to track finding contractors per task
  const [findingContractors, setFindingContractors] = useState({});

  // **New state to track email send status per vendor**
  const [emailSendStatus, setEmailSendStatus] = useState({});

  // Fetch the list of customers when the component mounts
  useEffect(() => {
    console.log('Fetching properties...');
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/get_folders`);
        setCustomers(response.data);
        console.log('Properties fetched:', response.data);
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    fetchProperties();
  }, []);

  const handleCustomerClick = async (customerName) => {
    setSelectedCustomer(customerName); // Set selected customer
    try {
      const response = await axios.get(`${apiBaseUrl}/get_description?folder_name=${customerName}`);
      setCustomerContent(response.data['description']); // Set the customer content
      setAddress(response.data['address']); // Set the address
    } catch (error) {
      console.error('Error fetching description:', error);
    }

    try {
      const response = await axios.get(`${apiBaseUrl}/get_images?folder_name=${customerName}`);
      setImgContent(response.data); // Set the customer content
    } catch (error) {
      console.error('Error fetching images:', error);
    }

    try {
      const response = await axios.get(`${apiBaseUrl}/get_diagnosis?folder_name=${customerName}`);
      setDiagnosisContent(response.data['diagnosis']); // Set the customer content
    } catch (error) {
      console.error('Error fetching diagnosis:', error);
    }

    try {
      const response = await axios.get(`${apiBaseUrl}/get_task_breakdown?folder_name=${customerName}`);
      setBreakdownContent(response.data); // Set the customer content
    } catch (error) {
      console.error('Error fetching task breakdown:', error);
    }

    // Reset selected vendor when a new customer is selected
    setSelectedVendor({ task: null, vendorType: null });
    // Clear contractor and preferred vendors content
    setContractorContent(null);
    setPreferredVendorsContent(null); // Clear preferred vendors content
    // Clear drafted emails
    setDraftedEmail(null);
    setDraftedVoiceEmail(null);
    setDraftedVoiceNoEmail(null);
    // Reset expanded descriptions
    setExpandedDescriptions({});
    // Reset finding contractors state
    setFindingContractors({});
    // **Reset email send status**
    setEmailSendStatus({});
  };

  const handleVendorTypeClick = (task, vendorType) => {
    if (selectedVendor.task === task && selectedVendor.vendorType === vendorType) {
      // Collapse the current section
      setSelectedVendor({ task: null, vendorType: null });
      // Reset content
      setContractorContent(null);
      setPreferredVendorsContent(null);
      setDraftedEmail(null);
      setDraftedVoiceEmail(null);
      setDraftedVoiceNoEmail(null);
      setExpandedDescriptions({});
    } else {
      // Expand the new section and collapse others
      setSelectedVendor({ task, vendorType });
      // Reset content
      setContractorContent(null);
      setPreferredVendorsContent(null);
      setDraftedEmail(null);
      setDraftedVoiceEmail(null);
      setDraftedVoiceNoEmail(null);
      setExpandedDescriptions({});
      // Fetch content based on vendorType
      if (vendorType === 'Third Party Vendors') {
        fetchContractorContent(selectedCustomer, task);
      } else if (vendorType === 'Preferred Vendors') {
        fetchPreferredVendorsContent(selectedCustomer, task);
        // **Fetch the AI Email Draft when Preferred Vendors tab is selected**
        fetchAnswerContent(selectedCustomer, task);
      } else if (vendorType === 'Outreach Drafts') {
        fetchAnswerContent(selectedCustomer, task);
      }
    }
  };

  const fetchContractorContent = async (customerName, task) => {
    try {
      const contractorType = task.replace(/ /g, "_"); // Ensure it matches backend expectations
      const response = await axios.get(`${apiBaseUrl}/get_contractors_csv?folder_name=${customerName}&contractor_type=${contractorType}`);
      setContractorContent(response.data['contractors']); // Set the contractor content
      console.log('Contractor content fetched:', response.data['contractors']);
      // Set findingContractors[task] to false
      setFindingContractors(prevState => ({ ...prevState, [task]: false }));
    } catch (error) {
      console.error('Error fetching contractors:', error);
      // Set findingContractors[task] to false even if there's an error
      setFindingContractors(prevState => ({ ...prevState, [task]: false }));
    }
  };

  const fetchPreferredVendorsContent = async (customerName, task) => {
    try {
      const category = task; // Assuming task name represents the category

      if (!address || !category) {
        alert('Address or Category information is missing.');
        return;
      }

      // Make the POST request to /retrieve_preferred_vendors_for_task API
      const response = await axios.post(`${apiBaseUrl}/retrieve_preferred_vendors_for_task`, {
        address: address,
        category: category
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setPreferredVendorsContent(response.data.preferred_vendors);
        console.log('Preferred Vendors content fetched:', response.data.preferred_vendors);
      } else {
        console.error('Unexpected response from the server.');
      }
    } catch (error) {
      console.error('Error fetching preferred vendors:', error);
      // Removed the alert to prevent annoying popups
    }
  };

  const fetchAnswerContent = async (customerName, task) => {
    try {
      const contractorType = task.replace(/ /g, "_");
      const response = await axios.get(`${apiBaseUrl}/get_drafted_email?folder_name=${customerName}&contractor_type=${contractorType}`);
      setDraftedEmail(response.data['drafted_email']); // Set drafted email
    } catch (error) {
      console.error('Error fetching drafted email:', error);
    }

    try {
      const contractorType = task.replace(/ /g, "_");
      const response = await axios.get(`${apiBaseUrl}/get_drafted_voicemail_email_followup?folder_name=${customerName}&contractor_type=${contractorType}`);
      setDraftedVoiceEmail(response.data['voicemail_followup']); // Set drafted voicemail with email
    } catch (error) {
      console.error('Error fetching drafted voicemail with email:', error);
    }

    try {
      const contractorType = task.replace(/ /g, "_");
      const response = await axios.get(`${apiBaseUrl}/get_drafted_voicemail_no_email?folder_name=${customerName}&contractor_type=${contractorType}`);
      setDraftedVoiceNoEmail(response.data['voicemail_no_email']); // Set drafted voicemail without email
      console.log('Drafted voicemail without email fetched:', response.data['voicemail_no_email']);
    } catch (error) {
      console.error('Error fetching drafted voicemail without email:', error);
    }
  };

  const handleSend = async (vendorType, vendorInfo = {}) => {
    // Implement the send functionality based on vendorType
    switch (vendorType) {
      case 'Third Party Vendors':
        // Example: Send contractor related data
        console.log('Sending Third Party Vendors Data:', vendorInfo);
        // Add your send logic here (e.g., API call)
        break;
      case 'Outreach Drafts':
        // Example: Send draftedVoiceNoEmail
        console.log('Sending Outreach Drafts Voicemail No Email:', draftedVoiceNoEmail);
        // Add your send logic here (e.g., API call)
        break;
      default:
        console.log('Unknown vendor type:', vendorType);
    }
  };

  /**
   * Handle "Find Third Party Vendors" Button Click
   */
  const handleFindThirdPartyVendors = async (task) => {
    try {
      const category = task; // Assuming task name represents the category

      if (!address || !category) {
        alert('Address or Category information is missing.');
        return;
      }

      // Set findingContractors[task] to true
      setFindingContractors(prevState => ({ ...prevState, [task]: true }));

      // Make the POST request to /find_contractors API
      const response = await axios.post(`${apiBaseUrl}/find_contractors`, {
        "address": address,
        "category": category
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 202) {
        alert('Task to find third party vendors has been started.');
      } else {
        alert('Unexpected response from the server.');
      }
    } catch (error) {
      console.error('Error initiating find third party vendors:', error);
      alert('Failed to initiate finding third party vendors.');
      // Set findingContractors[task] to false in case of error
      setFindingContractors(prevState => ({ ...prevState, [task]: false }));
    }
  };

  /**
   * Toggle the description visibility for a specific vendor
   */
  const toggleDescription = (vendorName) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [vendorName]: !prevState[vendorName]
    }));
  };

  /**
   * Handle sending email to preferred vendor
   */
  const handleSendToPreferredVendor = async (vendorName, vendor) => {
    // Check if vendor has an email address
    if (!vendor["email"]) {
      alert("Preferred Vendor doesn't have an email address");
      return;
    }

    // Check if draftedEmail is available
    if (!draftedEmail) {
      alert("Email draft not available.");
      return;
    }

    try {
      // Make the POST request to /send_email_to_vendor
      const response = await axios.post(`${apiBaseUrl}/send_email_to_vendor`, {
        email_text: draftedEmail,
        recipient_email: vendor["email"]
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        // Update emailSendStatus
        setEmailSendStatus(prevState => ({ ...prevState, [vendorName]: 'sent' }));
      } else {
        // Update emailSendStatus to error
        setEmailSendStatus(prevState => ({ ...prevState, [vendorName]: 'error' }));
        console.error('Error sending email:', response.data);
      }
    } catch (error) {
      // Update emailSendStatus to error
      setEmailSendStatus(prevState => ({ ...prevState, [vendorName]: 'error' }));
      console.error('Error sending email:', error);
    }
  };

  /**
   * Polling for Contractor and Preferred Vendors Updates Every 30 Seconds
   */
  useEffect(() => {
    // Function to poll updates
    const pollUpdates = () => {
      if (!selectedCustomer || !breakdownContent) return;

      const { task, vendorType } = selectedVendor;

      if (vendorType === 'Third Party Vendors') {
        fetchContractorContent(selectedCustomer, task);
      } else if (vendorType === 'Preferred Vendors') {
        fetchPreferredVendorsContent(selectedCustomer, task);
      }
    };

    // Check if a vendorType is selected
    const { vendorType } = selectedVendor;
    const hasVendors = vendorType === 'Third Party Vendors' || vendorType === 'Preferred Vendors';

    if (hasVendors) {
      // Start polling
      pollingRef.current = setInterval(pollUpdates, 30000); // 30 seconds
      console.log('Started polling for contractor and preferred vendors updates.');
    }

    // Cleanup function to clear the interval
    return () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
        pollingRef.current = null;
        console.log('Stopped polling for contractor and preferred vendors updates.');
      }
    };
  }, [selectedVendor, selectedCustomer, breakdownContent, address]);

  return (
    <div className="font-sans p-6">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">
        Repair Tickets
      </h1>

      <div className="flex flex-col md:flex-row">
        {/* Left Column: Customer List */}
        <div className="w-full md:w-1/5">
          {customers.map((customerName) => {
            // Determine font size based on the length of the customer name
            let fontSizeClass = 'text-base'; // Default font size
            if (customerName.length > 30) {
              fontSizeClass = 'text-sm';
            } else if (customerName.length > 20) {
              fontSizeClass = 'text-md';
            }

            return (
              <button
                key={customerName}
                className={`w-full text-left bg-blue-500 text-white px-4 py-4 mb-4 rounded hover:bg-blue-600 focus:outline-none whitespace-nowrap overflow-hidden text-ellipsis ${fontSizeClass}`}
                onClick={() => handleCustomerClick(customerName)}
                title={customerName} // Tooltip to show full text on hover
              >
                {customerName}
              </button>
            );
          })}
        </div>

        {/* Right Column: Customer Content */}
        <div className="w-full md:w-4/5 pl-0 md:pl-6 mt-6 md:mt-0">
          {selectedCustomer ? (
            <div className="bg-white p-6 rounded shadow-md">
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                {selectedCustomer}
              </h2>

              {/* Symptoms */}
              <p className="text-gray-800 mb-4">
                <strong>Symptoms:</strong>
              </p>
              <pre className="bg-gray-100 p-4 rounded mb-6 whitespace-pre-wrap">
                {customerContent}
              </pre>

              {/* Display Pictures */}
              <p className="text-gray-800 mb-4">
                <strong>Pictures:</strong>
              </p>
              {imgContent ? (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-6">
                  {Object.keys(imgContent).map((tmp, index) => (
                    <img
                      key={index}
                      src={`data:image/jpeg;base64,${imgContent[tmp]}`} // Decode base64 image
                      alt={`Picture ${index + 1}`}
                      className="w-full h-auto rounded"
                    />
                  ))}
                </div>
              ) : (
                <p className="text-gray-600">No pictures available.</p>
              )}

              {/* Diagnosis */}
              <p className="text-gray-800 mb-4">
                <strong>Diagnosis:</strong>
              </p>
              <pre className="bg-gray-100 p-4 rounded mb-6 whitespace-pre-wrap">
                {diagnosisContent}
              </pre>

              {/* Action Plan */}
              <p className="text-gray-800 mb-4">
                <strong>Action Plan:</strong>
              </p>
              {breakdownContent ? (
                <div className="space-y-6">
                  {Object.entries(breakdownContent).map(([task, info]) => (
                    <div key={task} className="mb-4">
                      {/* Task Header */}
                      <div
                        className="border border-gray-300 p-4 rounded bg-gray-200"
                      >
                        <strong>{task}</strong>
                        <div className="ml-4">
                          <strong>Summary:</strong> {info.summary} <br />
                          <strong>Details:</strong> {info.details} <br />
                        </div>

                        {/* Vendor Type Toggle Buttons */}
                        <div className="flex space-x-4 mt-4">
                          {/* Preferred Vendors Button */}
                          <button
                            className={`px-4 py-2 rounded focus:outline-none ${
                              selectedVendor.task === task && selectedVendor.vendorType === 'Preferred Vendors'
                                ? 'bg-green-600 text-white'
                                : 'bg-green-500 text-white hover:bg-green-600'
                            }`}
                            onClick={() => handleVendorTypeClick(task, 'Preferred Vendors')}
                          >
                            Preferred Vendors
                          </button>
                          {/* Third Party Vendors Button */}
                          <button
                            className={`px-4 py-2 rounded focus:outline-none ${
                              selectedVendor.task === task && selectedVendor.vendorType === 'Third Party Vendors'
                                ? 'bg-blue-600 text-white'
                                : 'bg-blue-500 text-white hover:bg-blue-600'
                            }`}
                            onClick={() => handleVendorTypeClick(task, 'Third Party Vendors')}
                          >
                            Third Party Vendors
                          </button>
                          {/* Outreach Drafts Button */}
                          <button
                            className={`px-4 py-2 rounded focus:outline-none ${
                              selectedVendor.task === task && selectedVendor.vendorType === 'Outreach Drafts'
                                ? 'bg-purple-600 text-white'
                                : 'bg-purple-500 text-white hover:bg-purple-600'
                            }`}
                            onClick={() => handleVendorTypeClick(task, 'Outreach Drafts')}
                          >
                            Outreach Drafts
                          </button>
                        </div>
                      </div>

                      

                      {/* Content appears based on selected vendor type */}
                      {selectedVendor.task === task && selectedVendor.vendorType === 'Third Party Vendors' && (
                        <div className="p-2 rounded mt-4">
                          {contractorContent && Object.keys(contractorContent).length > 0 ? (
                            Object.entries(contractorContent).map(([contractorID, contractor], index) => (
                              <div key={contractorID} className="p-4 mb-4 ml-4 border border-gray-300 rounded">
                                {/* Reordered and Styled Contractor Fields */}
                                <p className="text-lg font-bold">
                                  Name: {contractor["name"]}
                                </p>
                                <p className="text-lg font-bold">
                                  Rating on Google Maps: {contractor["rating on Google Maps"]}
                                </p>
                                <p>
                                  <strong>Address:</strong> {contractor["address"]}
                                </p>
                                <p>
                                  <strong>Phone Number:</strong> {contractor["phone number"]}
                                </p>
                                <p>
                                  <strong>Scraped Email Addresses:</strong> {contractor["scraped email addresses"]}
                                </p>
                                <p>
                                  <strong>Website:</strong> {contractor["website"]}
                                </p>
                                <p>
                                  <strong>Google Maps Link:</strong> <a href={contractor["Google Maps Link"]} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">View on Maps</a>
                                </p>
                                <p>
                                  <strong>ID:</strong> {contractorID}
                                </p>
                                {/* Send Button */}
                                <button
                                  className="mt-2 px-3 py-2 bg-red-500 text-white rounded hover:bg-red-700 focus:outline-none flex ml-auto"
                                  onClick={() => handleSend('Third Party Vendors', contractor)}
                                >
                                  Send
                                </button>
                              </div>
                            ))
                          ) : (
                            <div className="flex items-center">
                              {findingContractors[task] ? (
                                <p className="text-gray-600">
                                  Finding the best third party contractors near you. This process will take about 5 minutes.
                                </p>
                              ) : (
                                <>
                                  <p className="text-gray-600">No contractor information available.</p>
                                  {/* "Find Third Party Vendors" Button */}
                                  <button
                                    className="ml-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                    onClick={() => handleFindThirdPartyVendors(task)}
                                  >
                                    Find Third Party Vendors
                                  </button>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {/* Preferred Vendors Section */}
                      {selectedVendor.task === task && selectedVendor.vendorType === 'Preferred Vendors' && (
                        <div className="p-8 rounded mt-4">
                          {preferredVendorsContent && Object.keys(preferredVendorsContent).length > 0 ? (
                            Object.entries(preferredVendorsContent).map(([vendorName, vendor], index) => (
                              <div key={vendorName} className="p-4 mb-4 ml-4 border border-gray-300 rounded">
                                {/* Preferred Vendor Fields */}
                                <p className="text-lg font-bold">
                                  Name: {vendorName}
                                </p>
                                <p>
                                  <strong>Specialty:</strong> {vendor["specialty"].join(', ')}
                                </p>
                                <p>
                                  <strong>Email:</strong> <a href={`mailto:${vendor["email"]}`} className="text-blue-500 underline">{vendor["email"]}</a>
                                </p>
                                <p>
                                  <strong>Phone Number:</strong> {vendor["phone number"]}
                                </p>
                                <p>
                                  <strong>Address:</strong> {vendor["address"]}
                                </p>
                                <p>
                                  <strong>Notes:</strong> {vendor["notes"]}
                                </p>

                                {/* Toggle Description Button */}
                                <button
                                  className="mt-2 px-3 py-1 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 focus:outline-none"
                                  onClick={() => toggleDescription(vendorName)}
                                >
                                  {expandedDescriptions[vendorName] ? 'Hide Description' : 'Show Description'}
                                </button>

                                {/* Conditionally Render Description */}
                                {expandedDescriptions[vendorName] && (
                                  <div>
                                    <p className="mt-4 font-semibold">
                                      Description:
                                    </p>
                                    <pre className="bg-gray-100 p-2 rounded mb-2 whitespace-pre-wrap">
                                      {vendor["description"]}
                                    </pre>
                                  </div>
                                )}

                                {/* Send Button */}
                                {emailSendStatus[vendorName] === 'sent' ? (
                                  <div className="mt-2 px-3 py-2 bg-green-500 text-white rounded">
                                    Email Sent!
                                  </div>
                                ) : emailSendStatus[vendorName] === 'error' ? (
                                  <div className="mt-2 px-3 py-2 bg-yellow-500 text-white rounded">
                                    Error
                                  </div>
                                ) : (
                                  <button
                                    className="mt-2 px-3 py-2 bg-red-500 text-white rounded hover:bg-red-700 focus:outline-none"
                                    onClick={() => handleSendToPreferredVendor(vendorName, vendor)}
                                  >
                                    Send
                                  </button>
                                )}
                              </div>
                            ))
                          ) : (
                            <div className="flex items-center">
                              <p className="text-gray-600">No preferred vendors available.</p>
                              {/* "Find Third Party Vendors" Button */}
                              <button
                                className="ml-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                onClick={() => {
                                  handleVendorTypeClick(task, 'Third Party Vendors');
                                  handleFindThirdPartyVendors(task);
                                }}
                              >
                                Find Third Party Vendors
                              </button>
                            </div>
                          )}
                        </div>
                      )}

                      {/* Outreach Drafts Section */}
                      {selectedVendor.task === task && selectedVendor.vendorType === 'Outreach Drafts' && (
                        <div className="space-y-6 mt-4">
                          {/* Email Box */}
                          <div className="border-2 border-blue-300 rounded-lg text-center bg-blue-50 p-4">
                            <p className="font-semibold text-blue-700 mb-2 text-xl">
                              AI Email Draft
                            </p>
                            <p className="text-s text-gray-600 mb-2 break-words whitespace-normal">
                              {draftedEmail || 'No draft available.'}
                            </p>
                          </div>

                          {/* Voice & Email Box */}
                          <div className="border-2 border-green-300 rounded-lg text-center bg-green-50 p-4">
                            <p className="font-semibold text-green-700 mb-2 text-xl">
                              AI Voicemail Script (email found)
                            </p>
                            <p className="text-s text-gray-600 mb-2 break-words whitespace-normal">
                              {draftedVoiceEmail || 'No draft available.'}
                            </p>
                          </div>

                          {/* Voice Box */}
                          <div className="border-2 border-yellow-300 rounded-lg text-center bg-yellow-50 p-4">
                            <p className="font-semibold text-yellow-700 mb-2 text-xl">
                              AI Voicemail Script (email not found)
                            </p>
                            <p className="text-s text-gray-600 mb-2 break-words whitespace-normal">
                              {draftedVoiceNoEmail || 'No draft available.'}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-gray-600">
                  No breakdown content available.
                </div>
              )}
            </div>
          ) : (
            <div className="text-gray-600">
              Please select a customer to view its contents.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RepairTickets;
