// Updated App.jsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Login from './Components/Login';
import RepairTickets from './pages/RepairTickets';
import Lease from './pages/Knowledge/Lease';
import Repair from './pages/Knowledge/Repair';
import CloneYourVoice from './pages/Custom/CloneYourVoice';
import CampaignEditor from './pages/Custom/CampaignEditor';
import Demo from './pages/Demo';
import ResultDashboard from './pages/ResultDashboard';

const App = () => {
  const [apiBaseUrl, setApiBaseUrl] = useState('');

  useEffect(() => {
    // Load the configuration file
    fetch('/config.json')
      .then(response => response.json())
      .then(config => {
        setApiBaseUrl(config.apiBaseUrl);
      })
      .catch(error => console.error('Error loading config:', error));
  }, []);

  return (
    <Router>
      <Routes>
        {/* Public Route */}
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/*"
          element={<Layout apiBaseUrl={apiBaseUrl} />}
        />
      </Routes>
    </Router>
  );
};

// Layout component includes the Navbar and nested routes
const Layout = ({ apiBaseUrl }) => {
  return (
    <>
      <Navbar />
      <div className="container mx-auto mt-4">
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          {/* <Route path="/repair-tickets" element={<RepairTickets apiBaseUrl={apiBaseUrl} />} /> */}
          
          {/* Knowledge Routes */}
          {/* <Route path="/knowledge">
            <Route path="lease" element={<Lease apiBaseUrl={apiBaseUrl} />} />
            <Route path="repair" element={<Repair apiBaseUrl={apiBaseUrl} />} />
          </Route> */}

          {/* Custom Routes */}
          {/* <Route path="/custom">
            <Route path="clone-your-voice" element={<CloneYourVoice apiBaseUrl={apiBaseUrl} />} />
            <Route path="campaign-editor" element={<CampaignEditor apiBaseUrl={apiBaseUrl} />} />
          </Route> */}

          <Route path="/demo" element={<Demo apiBaseUrl={apiBaseUrl} />} />
          <Route path="/demo/results-dashboard" element={<ResultDashboard apiBaseUrl={apiBaseUrl} />} />

          {/* Fallback Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
};

// Not Found Page
const NotFound = () => {
  return (
    <div className="p-6 text-center">
      <h1 className="text-3xl font-bold">404 - Page Not Found</h1>
      <p className="text-gray-600">The page you are looking for does not exist.</p>
    </div>
  );
};

export default App;

