import React, { createContext, useState } from 'react';

// Create the context
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    clientID: '',
    agent_name: '',
    email: '',
    phone: '',
    company: '',
  });

  const [selectedProperty, setSelectedProperty] = useState(null);

  const updateUserData = (data) => {
    setUserData(data);
  };

  const updateSelectedProperty = (data) => {
    setSelectedProperty(data);
  };

  return (
    <UserContext.Provider value={{ userData, updateUserData, selectedProperty, updateSelectedProperty }}>
      {children}
    </UserContext.Provider>
  );
};
