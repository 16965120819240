import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const Login = () => {
  const [userData, setUserData] = useState({
    clientID: process.env.REACT_APP_JMS_ID || '',
    agent_name: 'Phillip Hwang',
    email: 'phillip@kindredpm.ai',
    phone: '971-238-2109',
    company: 'KindredPM',
  });

  const { updateUserData } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Add authentication logic here
    // For now, we'll assume login is successful
    updateUserData(userData);
    navigate('/demo');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="flex flex-col md:flex-row bg-white rounded shadow-md w-full max-w-4xl">
        {/* Instructions Section */}
        <div className="md:w-1/2 p-8 border-r">
          <h2 className="text-2xl font-bold mb-4">Instructions</h2>
          <p className="text-gray-700 mb-4">
            Welcome to KindredPM. Please follow the the below instructions to see a demo of our product.
          </p>
          <ol className="list-decimal list-inside text-gray-700 space-y-2">
            <li>
              Pretend you're on Zillow looking for your next rental home. Pick your favorite (fake) rental property that you'd like to live in!
            </li>
            <li>
              What would YOU want to know for your next home? Take a look at the selected property listing and click "Ask a question"
            </li>
            <li>
              Enter your cell phone number and email address and type in your question, e.g., "whats the total move-in cost?", "can i schedule viewing", "What's the pet policy?". 
              Your contact info will be used for demo purposes only.
            </li>
            <li>
              You will receive an AI response via text and email in about 30 seconds. Chat with Kindred as if it were a real leasing agent!
            </li>
            <li>
              Feel free to try to trick Kindred or ask complex questions. It will NOT hallucinate or respond to any questions that may go against the Fair Housing Act. 
            </li>
          </ol>
          <p className="text-gray-700 mt-4">
            If you encounter any problems, please take a look at the demo video{' '}
            <a href="https://www.youtube.com/watch?v=Thb-oDKjglo" className="text-blue-500 underline">
              here
            </a>{' '}
            for a sample demonstration.
          </p>
        </div>

        {/* Login Form Section */}
        <div className="md:w-1/2 p-8">
          <form
            onSubmit={handleSubmit}
            className="bg-white p-6 rounded shadow-md w-full"
          >
            <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Client ID</label>
              <input
                type="text"
                name="clientID"
                value={userData.clientID}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded mt-1"
              />
            </div>
            {/* <div className="mb-4">
              <label className="block text-gray-700">Agent Name</label>
              <input
                type="text"
                name="agent_name"
                value={userData.agent_name}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={userData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Phone</label>
              <input
                type="tel"
                name="phone"
                value={userData.phone}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded mt-1"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700">Company</label>
              <input
                type="text"
                name="company"
                value={userData.company}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded mt-1"
              />
            </div> */}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200"
            >
              Try out Kindred!
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
