// Updated App.jsx
import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Login from './Components/Login';
import RepairTickets from './pages/RepairTickets';
import Lease from './pages/Knowledge/Lease';
import Repair from './pages/Knowledge/Repair';
import CloneYourVoice from './pages/Custom/CloneYourVoice';
import CampaignEditor from './pages/Custom/CampaignEditor';
import Demo from './pages/Demo';
import ConsolePage from './pages/ConsolePage.tsx';
import ResultDashboard from './pages/ResultDashboard';
import { UserContext } from './context/UserContext';

const App = () => {
  const apiBaseUrl = process.env.REACT_APP_FLASKSERVER || '';
  if (!apiBaseUrl) {
    console.error('FLASKSERVER environment variable is not set');
  }
  const { userData, setUserData } = useContext(UserContext); // Access userData from context



  return (
    <Router>
      <Routes>
        {/* Public Route */}
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/*"
          element={<Layout apiBaseUrl={apiBaseUrl} client_id={userData.clientID}/>}
        />
      </Routes>
    </Router>
  );
};

// Layout component includes the Navbar and nested routes
const Layout = ({ apiBaseUrl, client_id }) => {
  return (
    <>
      <Navbar />
      <div className="container mx-auto mt-4">
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          {/* <Route path="/repair-tickets" element={<RepairTickets apiBaseUrl={apiBaseUrl} />} /> */}
          
          {/* Knowledge Routes */}
          {/* <Route path="/knowledge">
            <Route path="lease" element={<Lease apiBaseUrl={apiBaseUrl} />} />
            <Route path="repair" element={<Repair apiBaseUrl={apiBaseUrl} />} />
          </Route> */}

          {/* Custom Routes */}
          {/* <Route path="/custom">
            <Route path="clone-your-voice" element={<CloneYourVoice apiBaseUrl={apiBaseUrl} />} />
            <Route path="campaign-editor" element={<CampaignEditor apiBaseUrl={apiBaseUrl} />} />
          </Route> */}

          (client_id ? <Route path="/demo" element={<Demo apiBaseUrl={apiBaseUrl} client_id={client_id} />} /> : null)
          (apiBaseUrl ? <Route path="/demo/results-dashboard" element={<ResultDashboard apiBaseUrl={apiBaseUrl} />} />)
          (<Route path="/demo/callcenter" element={<ConsolePage apiBaseUrl={apiBaseUrl} client_id={client_id} />} />)

          {/* Fallback Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
};

// Not Found Page
const NotFound = () => {
  return (
    <div className="p-6 text-center">
      <h1 className="text-3xl font-bold">404 - Page Not Found</h1>
      <p className="text-gray-600">The page you are looking for does not exist.</p>
    </div>
  );
};

export default App;

