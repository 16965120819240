import React, { useState, useRef } from 'react';

const CloneYourVoice  = ({ apiBaseUrl }) => {
  const messages = [
    "Hi, David Bradford! How are you doing today? This is (full name) from (company name).",
    "Thanks for the interest in the property at Emerald Street. I just sent you an email with the next steps.",
    "Please check it out. I also included a detailed response to your question. Have a great day!",
    "We have hundreds of properties in the (your city) area, and I'd love to help you find your next home.",
    "One, Two, Three, Four, Five, Six, Seven, Eight",
    "Nine, Ten, Eleven, Twelve, Thirteen, Fourteen",
    "Fifteen, Sixteen, Seventeen, Eighteen, Nineteen"
  ];

  const [recordings, setRecordings] = useState(Array(messages.length).fill(null));  // Store the audio URLs
  const [activeRecordingIndex, setActiveRecordingIndex] = useState(null);  // Track which button is active (recording)
  const mediaRecorderRef = useRef(null);  // Ref to hold MediaRecorder instance
  const audioChunksRef = useRef([]);      // Ref to hold audio data chunks

  // Start recording
  const handleRecordStart = async (index) => {
    setActiveRecordingIndex(index);  // Set the index of the message being recorded

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    audioChunksRef.current = [];
    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);

      const newRecordings = [...recordings];
      newRecordings[index] = audioUrl;  // Store the recorded audio
      setRecordings(newRecordings);
      setActiveRecordingIndex(null);  // Reset recording state after stopping
    };

    mediaRecorderRef.current.start();
  };

  // Stop recording
  const handleRecordStop = () => {
    mediaRecorderRef.current.stop();  // Stop the recording
  };

  // Handle audio file upload
  const handleUpload = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const newRecordings = [...recordings];
      newRecordings[index] = URL.createObjectURL(file);  // Store the uploaded file URL for playback
      setRecordings(newRecordings);
    }
  };

  // Handle save button click (for demonstration purposes)
  const handleSave = (index) => {
    alert(`Recording ${index + 1} saved!`);
  };

  return (
    <div>
      <h1 className="text-center text-5xl font-extrabold text-white mb-12 drop-shadow-md">Messages & Audio Upload</h1>
      <div className="grid grid-cols-1 gap-10">
        {messages.map((message, index) => (
          <div key={index} className="bg-white border-2 border-indigo-300 p-6 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out transform hover:-translate-y-1">
            <h5 className="font-bold text-xl mb-4 text-gray-700">
              <span className="bg-indigo-200 text-indigo-800 py-1 px-3 rounded-full mr-2">{index + 1}</span>
              {message}
            </h5>
            <p className="text-gray-500 mb-6">
              Please upload a supported audio file. Maximum size is 10 MB.
            </p>

            {/* File Upload Button */}
            <label className="bg-gradient-to-r from-blue-400 to-blue-600 text-white px-5 py-2 rounded-full cursor-pointer shadow-md hover:shadow-lg hover:scale-105 transform transition duration-300 ease-in-out mr-4">
              <i className="bi bi-upload mr-2"></i> Upload File
              <input
                type="file"
                onChange={(e) => handleUpload(e, index)}
                accept="audio/*"
                className="hidden"
              />
            </label>

            {/* Record and Stop Buttons */}
            {activeRecordingIndex === index ? (
              <button
                className="bg-gradient-to-r from-red-400 to-red-600 text-white px-5 py-2 rounded-full shadow-md hover:shadow-lg hover:scale-105 transform transition duration-300 ease-in-out"
                onClick={handleRecordStop}
              >
                <i className="bi bi-stop-fill mr-2"></i> Stop Recording
              </button>
            ) : (
              <button
                className="bg-gradient-to-r from-green-400 to-green-600 text-white px-5 py-2 rounded-full shadow-md hover:shadow-lg hover:scale-105 transform transition duration-300 ease-in-out"
                onClick={() => handleRecordStart(index)}
              >
                <i className="bi bi-mic-fill mr-2"></i> Start Recording
              </button>
            )}

            {/* Display Recorded Audio or Uploaded Audio */}
            {recordings[index] && (
              <div className="mt-6">
                <audio controls className="w-full">
                  <source src={recordings[index]} type="audio/wav" />
                  Your browser does not support the audio element.
                </audio>

                {/* Download Button */}
                <a
                  href={recordings[index]}
                  download={`recording-${index + 1}.wav`}
                  className="mt-4 inline-block bg-gradient-to-r from-yellow-400 to-yellow-600 text-white px-5 py-2 rounded-full shadow-md hover:shadow-lg hover:scale-105 transform transition duration-300 ease-in-out"
                >
                  Download Recording
                </a>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CloneYourVoice;
