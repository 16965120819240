// Demo.jsx
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../context/UserContext';
import zillow_logo from '../zillow_logo.png'; // Ensure the correct path to your logo file

// Separate component for an input field to minimize re-renders
const InputField = React.memo(({ label, name, value, onChange, type = "text", required = false }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700 mb-1">{label}:</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  );
});

const Demo = ({ apiBaseUrl }) => { // Accept apiBaseUrl as a prop
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(UserContext); // Access userData from context

  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [filters, setFilters] = useState({
    priceRange: [0, 5000],
    beds: 'Any',
    baths: 'Any',
    moveInDate: '',
    sqftRange: [0, 5000],
    pets: [],
    amenities: [],
    keyword: '',
  });
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [actionModal, setActionModal] = useState(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/getproperties?client_id=111330394966390895921`);
        
        setProperties(response.data);
        setFilteredProperties(response.data);
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    fetchProperties();
  }, [apiBaseUrl]); // Add apiBaseUrl to dependencies

  useEffect(() => {
    const applyFilters = () => {
      const filtered = properties.filter(property => {
        const price = property["base monthly rent"];
        const beds = property["rooms"];
        const baths = property["baths"];
        const sqft = property["square feet"];
        const availableDate = new Date(property["date available"]);

        // Handle Pet Restrictions
        const allowsPets = !property.restrictions["no pets"]["value"];

        return (
          price >= filters.priceRange[0] &&
          price <= filters.priceRange[1] &&
          (filters.beds === 'Any' || beds >= parseInt(filters.beds)) &&
          (filters.baths === 'Any' || baths >= parseInt(filters.baths)) &&
          // (filters.moveInDate === '' || availableDate <= new Date(filters.moveInDate)) &&
          sqft >= filters.sqftRange[0] &&
          sqft <= filters.sqftRange[1] &&
          (filters.pets.length === 0 || 
            (filters.pets.includes('Pets allowed') && allowsPets) ||
            (filters.pets.includes('Pets not allowed') && !allowsPets)
          ) &&
          (filters.amenities.length === 0 || filters.amenities.every(amenity => 
            property.amenities.some(propAmenity => propAmenity.toLowerCase().includes(amenity.toLowerCase()))
          )) &&
          (filters.keyword === '' || property.description.toLowerCase().includes(filters.keyword.toLowerCase()))
        );
      });

      setFilteredProperties(filtered);
    };

    applyFilters();
  }, [filters, properties]);

  const handleFilterChange = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handlePropertyClick = (property) => {
    console.log('Property selected:', property);
    setSelectedProperty(property);
  };

  const closeModal = () => {
    console.log('Modal closed');
    setSelectedProperty(null);
  };

  const handleActionClick = (action) => {
    console.log('Action clicked:', action);
    setActionModal(action);
  };

  const closeActionModal = () => {
    setActionModal(null);
    setFirstName('');
    setLastName('');
    setPhone('');
    setEmail('');
    setMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userData) {
      console.error('Form submission failed: userData is null.');
      alert('An error occurred. Please try logging in again.');
      return;
    }

    try {
      // Optionally, you can update or use userData here
      const updatedUserData = {
        ...userData,
        firstName,
        lastName,
        phone,
        email,
      };
      // setUserData(updatedUserData); // Update context if needed

      console.log('Form submitted with:', { updatedUserData });

      // Submit form data to the backend
      const response = await axios.post(`${apiBaseUrl}/formsubmissions`, { // Use apiBaseUrl
        "client_id": "111330394966390895921",
        "What's your name?": `${firstName} ${lastName}`,
        "What's your phone number? ": phone,
        "What's your email address?": email,
        "What EXACT name did you use to clone your voice? ": userData.agent_name,
        "What EXACT company did you use to clone your voice? ": userData.company,
        "Which property are you interested in? ": selectedProperty["Kindred_ID"],
        "Ask any question about the property!": message,
        "Why did you choose that property?": `Filters used: ${JSON.stringify(filters)}. Action: ${actionModal}`
      });

      console.log('Form submitted successfully:', response.data);
      closeActionModal();

      // Navigate to ResultDashboard and pass user data
      navigate("/demo/results-dashboard", { state: { userData: updatedUserData } });
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit the form. Please try again.');
    }
  };

  return (
    <div className="flex h-screen font-sans bg-gray-100">
      {/* Filters and Instructions Section */}
      <div className="w-1/4 p-6 bg-white border-r border-gray-200 shadow-lg overflow-y-auto">
        <img src={zillow_logo} alt="Zillow Logo" className="h-12 sm:h-10 md:h-10 mb-6" />

        {/* Price Range */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Price Range: ${filters.priceRange[0]} - ${filters.priceRange[1]}</label>
          <div className="flex space-x-2">
            <input
              type="range"
              min="0"
              max="6000"
              value={filters.priceRange[0]}
              onChange={(e) => handleFilterChange('priceRange', [parseInt(e.target.value), filters.priceRange[1]])}
              className="w-full"
            />
            <input
              type="range"
              min="0"
              max="6000"
              value={filters.priceRange[1]}
              onChange={(e) => handleFilterChange('priceRange', [filters.priceRange[0], parseInt(e.target.value)])}
              className="w-full"
            />
          </div>
        </div>

        {/* Beds */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Beds:</label>
          <select
            value={filters.beds}
            onChange={(e) => handleFilterChange('beds', e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="Any">Any</option>
            <option value="1">1+</option>
            <option value="2">2+</option>
            <option value="3">3+</option>
            <option value="4">4+</option>
            <option value="5">5+</option>
          </select>
        </div>

        {/* Baths */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Baths:</label>
          <select
            value={filters.baths}
            onChange={(e) => handleFilterChange('baths', e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="Any">Any</option>
            <option value="1">1+</option>
            <option value="2">2+</option>
            <option value="3">3+</option>
            <option value="4">4+</option>
            <option value="5">5+</option>
          </select>
        </div>

        {/* Square Feet */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">
            Square Feet: {filters.sqftRange[0]} - {filters.sqftRange[1]} sq ft
          </label>
          <div className="flex space-x-2">
            <input
              type="range"
              min="0"
              max="5000"
              value={filters.sqftRange[0]}
              onChange={(e) => handleFilterChange('sqftRange', [parseInt(e.target.value), filters.sqftRange[1]])}
              className="w-full"
            />
            <input
              type="range"
              min="0"
              max="5000"
              value={filters.sqftRange[1]}
              onChange={(e) => handleFilterChange('sqftRange', [filters.sqftRange[0], parseInt(e.target.value)])}
              className="w-full"
            />
          </div>
        </div>

        {/* Pets */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Pets:</label>
          <div className="flex flex-col">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={filters.pets.includes('Pets allowed')}
                onChange={(e) => {
                  const newPets = e.target.checked
                    ? [...filters.pets, 'Pets allowed']
                    : filters.pets.filter(p => p !== 'Pets allowed');
                  handleFilterChange('pets', newPets);
                }}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2">Pets allowed</span>
            </label>
            <label className="inline-flex items-center mt-2">
              <input
                type="checkbox"
                checked={filters.pets.includes('Pets not allowed')}
                onChange={(e) => {
                  const newPets = e.target.checked
                    ? [...filters.pets, 'Pets not allowed']
                    : filters.pets.filter(p => p !== 'Pets not allowed');
                  handleFilterChange('pets', newPets);
                }}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2">Pets not allowed</span>
            </label>
          </div>
        </div>

        {/* Instructions Section */}
        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4">Instructions</h2>
          <p className="text-gray-700 mb-4">
            Welcome to KindredPM. Please follow the the below instructions to see a demo of our product.
          </p>
          <ol className="list-decimal list-inside text-gray-700 space-y-2">
            <li>
              Pretend you're on Zillow looking for your next rental home. Pick your favorite (fake) rental property that you'd like to live in!
            </li>
            <li>
              What would YOU want to know for your next home? Take a look at the selected property listing and click "Ask a question"
            </li>
            <li>
              Enter your cell phone number and email address and type in your question, e.g., "whats the total move-in cost?", "can i schedule viewing", "What's the pet policy?". 
              Your contact info will be used for demo purposes only.
            </li>
            <li>
              You will receive an AI response via text and email in about 30 seconds. Chat with Kindred as if it were a real leasing agent!
            </li>
            <li>
              Feel free to try to trick Kindred or ask complex questions. It will NOT hallucinate or respond to any questions that may go against the Fair Housing Act. 
            </li>
          </ol>
          <p className="text-gray-700 mt-4">
            If you encounter any problems, please take a look at the demo video{' '}
            <a href="https://www.youtube.com/watch?v=Thb-oDKjglo" className="text-blue-500 underline">
              here
            </a>{' '}
            for a sample demonstration.
          </p>
        </div>
      </div>

      {/* Property Listings */}
      <div className="w-3/4 p-6 overflow-y-auto">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Property Listings ({filteredProperties.length})</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {filteredProperties.map(property => (
            <div
              key={property["Kindred_ID"]}
              className="bg-white rounded-lg shadow-md cursor-pointer transform hover:scale-105 transition-transform duration-200"
              onClick={() => handlePropertyClick(property)}
            >
              <img
                src={`data:image/jpeg;base64, ${property["thumbnail"]}`}  // Decode base64 image
                alt={property.address}
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800">{property.address}</h3>
                <p className="text-gray-600">Price: ${property["base monthly rent"]}/month</p>
                <p className="text-gray-600">Beds: {property["rooms"]} | Baths: {property["baths"]}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Property Details Modal */}
      {selectedProperty && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-3xl w-full overflow-y-auto shadow-lg">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">{selectedProperty.address}</h2>
            <img
              src={`data:image/jpeg;base64, ${selectedProperty["thumbnail"]}`} 
              alt={selectedProperty.address}
              className="w-full h-64 object-cover rounded-md mb-4"
            />
            <p className="text-gray-700 mb-2"><strong>Price:</strong> ${selectedProperty["base monthly rent"]}/month</p>
            <p className="text-gray-700 mb-2"><strong>Beds:</strong> {selectedProperty["rooms"]} | <strong>Baths:</strong> {selectedProperty["baths"]}</p>
            <p className="text-gray-700 mb-2"><strong>Square Feet:</strong> {selectedProperty["square feet"]}</p>
            <p className="text-gray-700 mb-2"><strong>Description:</strong> {selectedProperty.description}</p>
            <p className="text-gray-700 mb-2"><strong>Date Available:</strong> {new Date(selectedProperty["date available"]).toLocaleDateString()}</p>
            {/* <p className="text-gray-700 mb-2"><strong>Amenities:</strong> {selectedProperty.amenities.join(', ')}</p> */}
            <p className="text-gray-700 mb-2">
              <strong>Pet Policy:</strong> {selectedProperty.restrictions["no pets"]["value"] ? "No pets allowed" : "Pets allowed. Contact management for details"}
            </p>
            <p className="text-gray-700 mb-4"><strong>Smoking Policy:</strong> {selectedProperty.restrictions["no smoking"]["value"] ? "No smoking allowed" : "Smoking allowed"}</p>
            <div className="flex justify-between">
              <button
                onClick={() => handleActionClick('Ask a question')}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
              >
                Ask a question
              </button>
              <button
                onClick={() => handleActionClick('Request a Tour')}
                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
              >
                Request a Tour
              </button>
              <button
                onClick={() => handleActionClick('Request to apply')}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
              >
                Request to apply
              </button>
            </div>
            <button
              onClick={closeModal}
              className="mt-6 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Action Modal */}
      {actionModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-md w-full shadow-md">
            <h2 className="text-xl font-semibold mb-4 text-gray-800">{actionModal}</h2>
            <form onSubmit={handleSubmit}>
              <InputField
                label="First Name"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <InputField
                label="Last Name"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
              <InputField
                label="Phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="tel"
                required
              />
              <InputField
                label="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
              />
              <InputField
                label="Message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                type="text"
              />

              <div className="flex justify-between mt-6">
                <button
                  type="submit"
                  className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={closeActionModal}
                  className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Demo;
