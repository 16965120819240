// Dropdown.js
import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const Dropdown = ({ label, links }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Optional: Handle Escape key to close the dropdown
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  return (
    <div
      className="relative inline-block"
      ref={dropdownRef}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <button
        className="text-gray-300 hover:bg-[#48547b] hover:text-white px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={() => setIsOpen((prev) => !prev)} // Toggle on click
      >
        {label}
      </button>
      {isOpen && (
        <div
          className="absolute left-0 top-full mt-0 w-40 bg-white rounded-md shadow-lg z-10 transition ease-out duration-100 transform opacity-100 scale-100"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby={`${label}-button`}
        >
          {links.map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              className={({ isActive }) =>
                `block px-4 py-2 text-gray-800 hover:bg-gray-100 ${
                  isActive ? 'bg-gray-100' : ''
                }`
              }
              onClick={() => setIsOpen(false)} // Close on selection
            >
              {link.label}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
