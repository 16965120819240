// src/pages/ResultDashboard.jsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';


const ResultDashboard = ({ apiBaseUrl }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = location.state || {}; // Retrieve userData from navigation state

  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingText, setLoadingText] = useState("AI is processing your inquiry");
  const [failedAttempts, setFailedAttempts] = useState(0); // Track the number of failed attempts

  useEffect(() => {
    const fetchResults = async () => {
      if (!userData || !userData.email) {
        setError('User data is missing or incomplete');
        console.log('User data:', userData);
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${apiBaseUrl}/getresults`, {
          params: {
            email: userData.email,
          }
        });

        console.log('API response:', response);

        if (response.status === 200) {
          setResults(response.data);
          setError(null); // Clear any previous error
          setLoading(false);

          // Stop polling if all fields are populated
          if (response.data.drafted_email && response.data.drafted_sms && response.data.drafted_voicemail) {
            setLoading(false);
          }
        } else if (response.status === 202) {
          setResults(response.data.partial_results);
          setError(null); // Clear any previous error
        } else {
          throw new Error('Unexpected response status');
        }
      } catch (error) {
        console.error('Error fetching results:', error);

        if (!results) { // Only track failed attempts if no results have been fetched yet
          setFailedAttempts(prev => prev + 1);
        }

        if (failedAttempts >= 3) { // Show error only after 3 failed attempts
          setError('Failed to fetch results. Please try again later.');
          setLoading(false);
        }
      }
    };

    if (userData) {
      const intervalId = setInterval(() => {
        fetchResults();
      }, 5000); // Poll every 5 seconds

      // Clear the interval if all data is fetched
      if (results && results.drafted_email && results.drafted_sms && results.drafted_voicemail) {
        clearInterval(intervalId);
      }

      return () => clearInterval(intervalId);
    }
  }, [apiBaseUrl, userData, results, failedAttempts]);

  useEffect(() => {
    const phrases = [
      "Response received. The AI will respond to the prospect within 2 minutes."
    ];
    let index = 0;

    const intervalId = setInterval(() => {
      setLoadingText(phrases[index]);
      index = (index + 1) % phrases.length;
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#3498db'
      }}>
        <div>{loadingText}</div>
      </div>
    );
  }

  if (error && !results) { // Only show the error if no results have been fetched yet
    return <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>;
  }

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ textAlign: 'center', color: '#2c3e50' }}>Results Dashboard</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        {/* <div style={{ width: '30%', padding: '20px', border: '1px solid #bdc3c7', borderRadius: '8px' }}>
          <h2 style={{ color: '#2980b9' }}>Email Drafted</h2>
          <p>{results?.drafted_email || 'Processing...'}</p>
        </div> */}
        <div style={{ width: '30%', padding: '20px', border: '1px solid #bdc3c7', borderRadius: '8px' }}>
          <h2 style={{ color: '#2980b9' }}>Email Drafted</h2>
          {results?.drafted_email ? (
            <pre style={{ 
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              fontFamily: 'Arial, sans-serif',
              fontSize: '14px',
              lineHeight: '1.6'
            }}>
              {results.drafted_email}
            </pre>
          ) : (
            <p>Processing...</p>
          )}
        </div>



        <div style={{ width: '30%', padding: '20px', border: '1px solid #bdc3c7', borderRadius: '8px' }}>
          <h2 style={{ color: '#27ae60' }}>SMS Drafted</h2>
          <p>{results?.drafted_sms || 'Processing...'}</p>
        </div>
        <div style={{ width: '30%', padding: '20px', border: '1px solid #bdc3c7', borderRadius: '8px' }}>
          <h2 style={{ color: '#c0392b' }}>Voicemail</h2>
          <p>{results?.drafted_voicemail || 'Processing...'}</p>
        </div>
      </div>
    </div>
  );
};

ResultDashboard.propTypes = {
  userData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired,
};

export default ResultDashboard;
