// Navbar.jsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import Dropdown from './Dropdown'; // Ensure the correct path
import logo from '../assets/trans back_white hori.png';

const Navbar = () => {
  const activeClass =
    'text-white bg-[#13318e] px-3 py-2 rounded-md text-sm font-medium';
  const inactiveClass =
    'text-gray-300 hover:bg-[#48547b] hover:text-white px-3 py-2 rounded-md text-sm font-medium';
    
  const knowledgeLinks = [
    { label: 'Lease', to: '/knowledge/lease' },
    { label: 'Repair', to: '/knowledge/repair' },
  ];

  const customLinks = [
    { label: 'Clone-Your Voice', to: '/custom/clone-your-voice' },
    { label: 'Campaign Editor', to: '/custom/campaign-editor' },
  ];

  return (
    <nav className="bg-[#182241]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo or Brand Name */}
          <div className="p-4 flex items-center justify-center">
            {/* Wrap the logo image with NavLink to make it clickable */}
            <NavLink to="/login">
              <img src={logo} alt="Logo" className="h-80 w-auto mr-3 cursor-pointer" />
            </NavLink>
            {/* <span className="font-bold text-2xl text-green-400 neon-text">KindredAI</span> */}
          </div>
          {/* <div className="flex-shrink-0">
            <NavLink to="/" className="text-white font-bold text-xl">
              MyApp
            </NavLink>
          </div> */}
          {/* Navigation Links */}
          
        </div>
      </div>

      {/* Mobile Menu (Optional) */}
      {/* Implement mobile responsiveness as needed */}
    </nav>
  );
};

export default Navbar;
