import React, { useState, useRef } from 'react';

const CampaignEditor = ({ apiBaseUrl }) => {
  // Initialize requiredWords as a state to allow dynamic updates
  const [requiredWords, setRequiredWords] = useState([
    "{Prospect's First Name}",
    "{Prospect's Last Name}",
    "{QnA}",
    "{property address}",
    "{city}",
    "{agent full name}",
    "{property_criteria_summary}",
    "{calendar link}"
  ]);

  const initialContent = {
    email: "Hi, David! I just sent you an email with the next steps for the property at Emerald Street. Please check it out!",
    sms: "Hi, David! I just sent you an SMS with the next steps for the property at Emerald Street. Please check it out!",
    voicemail: "Hi, David! I just sent you a voicemail with the next steps for the property at Emerald Street. Please check it out!"
  };

  const initialBox = {
    days: 3,
    isEditingDays: false,
    activeTextarea: null
  };

  const [boxes, setBoxes] = useState([{ id: 1, ...initialBox }]);
  const [content, setContent] = useState([{ ...initialContent }]);

  // Function to add a new box with independent buttons
  const addBox = () => {
    const nextId = boxes.length + 1;
    setBoxes([...boxes, { id: nextId, ...initialBox }]);
    setContent([...content, { ...initialContent }]);
  };

  // Function to delete a box
  const deleteBox = (index) => {
    setBoxes(boxes.filter((_, i) => i !== index));
    setContent(content.filter((_, i) => i !== index));
  };

  // Function to reset all content and box properties to default in a specific box
  const resetAllContentInBox = (boxIndex) => {
    const updatedContent = content.map((boxContent, i) =>
      i === boxIndex ? { ...initialContent } : boxContent
    );
    const updatedBoxes = boxes.map((box, i) =>
      i === boxIndex ? { ...box, ...initialBox } : box
    );
    setContent(updatedContent);
    setBoxes(updatedBoxes);
  };

  // Function to set the active editable div (email, sms, voicemail) in the box
  const setActiveTextarea = (boxIndex, textareaType) => {
    const updatedBoxes = boxes.map((box, index) =>
      index === boxIndex ? { ...box, activeTextarea: textareaType } : { ...box, activeTextarea: null }
    );
    setBoxes(updatedBoxes);
  };

  // Function to toggle between editing and showing the "Wait X days" button
  const handleEditDays = (index) => {
    const updatedBoxes = boxes.map((box, i) =>
      i === index ? { ...box, isEditingDays: !box.isEditingDays } : box
    );
    setBoxes(updatedBoxes);
  };

  // Function to handle changing the number of days
  const handleDaysChange = (event, index) => {
    const newDays = event.target.value;
    const updatedBoxes = boxes.map((box, i) =>
      i === index ? { ...box, days: newDays } : box
    );
    setBoxes(updatedBoxes);
  };

  // Function to insert bold and red text at the cursor position for the active editable div
  const insertAtCursor = (boxIndex, word) => {
    const activeTextarea = boxes[boxIndex].activeTextarea;
    if (!activeTextarea) return;

    const editableDiv = document.getElementById(`editable-${boxIndex}-${activeTextarea}`);
    if (!editableDiv) return;

    editableDiv.focus();

    const span = document.createElement("span");
    span.innerHTML = word;
    span.style.color = "red";
    span.style.fontWeight = "bold";
    span.contentEditable = "false"; // Make the span non-editable
    span.className = "placeholder"; // Assign a class for identification

    // Insert the span at the cursor position
    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);
    range.deleteContents();
    range.insertNode(span);

    // Move cursor after inserted text
    range.setStartAfter(span);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  // Handler to delete a placeholder when backspace is pressed
  const handleContentKeyDown = (e, boxIndex) => {
    if (e.key === 'Backspace') {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;
      const range = selection.getRangeAt(0);
      const node = range.startContainer;
      const offset = range.startOffset;

      // If the node is a text node and the offset is 0, check the previous sibling
      if (node.nodeType === Node.TEXT_NODE && offset === 0) {
        const prevSibling = node.previousSibling;
        if (prevSibling && prevSibling.classList && prevSibling.classList.contains('placeholder')) {
          e.preventDefault(); // Prevent default backspace
          prevSibling.remove(); // Remove the placeholder

          // Move cursor to the end of the previous node
          const newRange = document.createRange();
          newRange.setStartBefore(prevSibling);
          newRange.setEndBefore(prevSibling);
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
      }
      // If the node is an element and the cursor is right after a placeholder
      else if (node.nodeType === Node.ELEMENT_NODE && offset > 0) {
        const child = node.childNodes[offset - 1];
        if (child && child.classList && child.classList.contains('placeholder')) {
          e.preventDefault(); // Prevent default backspace
          child.remove(); // Remove the placeholder

          // Move cursor to the position of the removed placeholder
          const newRange = document.createRange();
          newRange.setStart(node, offset - 1);
          newRange.collapse(true);
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
      }
    }
  };

  // Handler to delete a placeholder button from the requiredWords list when backspace is pressed
  const handleButtonKeyDown = (e, word) => {
    if (e.key === 'Backspace') {
      e.preventDefault(); // Prevent default backspace behavior
      setRequiredWords(requiredWords.filter(w => w !== word));
    }
  };

  return (
    <div className="min-h-screen p-8 container mx-auto mt-10 p-4">
      <div className="flex justify-between mb-6">
        <h1 className="text-4xl font-extrabold text-gray-800">Campaign Editor</h1>
        <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600">
          Done
        </button>
      </div>

      {boxes.map((box, index) => (
        <div
          key={box.id}
          className="border-2 border-gray-300 p-4 bg-gradient-to-r from-gray-50 via-gray-50 to-gray-50 mb-6 rounded-xl shadow-md bg-white w-6/7 mx-auto transform transition duration-300 hover:shadow-lg"
        >
          <div className="flex justify-between items-center mb-2">
            <div className="text-xl font-bold text-gray-700">Outreach {box.id}</div>

            {/* Editable "Wait X Days" */}
            <div>
              {box.isEditingDays ? (
                <input
                  type="number"
                  value={box.days}
                  onChange={(event) => handleDaysChange(event, index)}
                  onBlur={() => handleEditDays(index)}
                  className="bg-yellow-100 border border-yellow-400 rounded py-1 px-3"
                />
              ) : (
                <button
                  className="bg-yellow-500 text-white py-1 px-3 rounded-full hover:bg-yellow-600 transform hover:scale-105 transition duration-300 ease-in-out"
                  onClick={() => handleEditDays(index)}
                >
                  Wait {box.days} days
                </button>
              )}
            </div>
          </div>

          {/* Buttons for this specific box */}
          <div className="mt-4 mb-4 space-x-2 text-center flex flex-wrap">
            {requiredWords.map((word, btnIndex) => (
              <button
                key={btnIndex}
                onClick={() => insertAtCursor(index, word)}
                onKeyDown={(e) => handleButtonKeyDown(e, word)}
                className="bg-blue-500 text-white py-1 px-2 rounded hover:bg-blue-600 m-1 focus:outline-none"
                tabIndex="0"
              >
                {word.replace(/[{}]/g, '')}
              </button>
            ))}
          </div>

          {/* Email, SMS, Voicemail editable divs */}
          <div className="space-y-4 mb-4">
            {/* Email */}
            <div className="p-3 rounded-lg bg-blue-100 cursor-pointer hover:bg-blue-200 transition duration-300 ease-in-out">
              <p className="font-semibold text-blue-700 mb-1 text-sm">Email</p>
              <div
                id={`editable-${index}-email`}
                className="w-full p-2 border border-blue-300 rounded bg-white"
                contentEditable
                suppressContentEditableWarning
                onFocus={() => setActiveTextarea(index, 'email')}
                onBlur={(e) => {
                  const updatedContent = [...content];
                  updatedContent[index].email = e.target.innerHTML;
                  setContent(updatedContent);
                }}
                onKeyDown={(e) => handleContentKeyDown(e, index)}
                dangerouslySetInnerHTML={{ __html: content[index].email }}
              />
            </div>

            {/* SMS */}
            <div className="p-3 rounded-lg bg-green-100 cursor-pointer hover:bg-green-200 transition duration-300 ease-in-out">
              <p className="font-semibold text-green-700 mb-1 text-sm">SMS</p>
              <div
                id={`editable-${index}-sms`}
                className="w-full p-2 border border-green-300 rounded bg-white"
                contentEditable
                suppressContentEditableWarning
                onFocus={() => setActiveTextarea(index, 'sms')}
                onBlur={(e) => {
                  const updatedContent = [...content];
                  updatedContent[index].sms = e.target.innerHTML;
                  setContent(updatedContent);
                }}
                onKeyDown={(e) => handleContentKeyDown(e, index)}
                dangerouslySetInnerHTML={{ __html: content[index].sms }}
              />
            </div>

            {/* Voicemail */}
            <div className="p-3 rounded-lg bg-yellow-100 cursor-pointer hover:bg-yellow-200 transition duration-300 ease-in-out">
              <p className="font-semibold text-yellow-700 mb-1 text-sm">Voicemail</p>
              <div
                id={`editable-${index}-voicemail`}
                className="w-full p-2 border border-yellow-300 rounded bg-white"
                contentEditable
                suppressContentEditableWarning
                onFocus={() => setActiveTextarea(index, 'voicemail')}
                onBlur={(e) => {
                  const updatedContent = [...content];
                  updatedContent[index].voicemail = e.target.innerHTML;
                  setContent(updatedContent);
                }}
                onKeyDown={(e) => handleContentKeyDown(e, index)}
                dangerouslySetInnerHTML={{ __html: content[index].voicemail }}
              />
            </div>
          </div>
          
          {/* Add, Reset, and Delete Buttons */}
          <div className="flex justify-between mt-4">
            <button
              className="bg-green-500 text-white py-1 px-3 rounded-full hover:bg-green-600 transform hover:scale-105 transition duration-300 ease-in-out text-sm"
              onClick={addBox}
            >
              Add
            </button>
            
            {/* Reset Button */}
            <div className="space-x-2">
              <button
                onClick={() => resetAllContentInBox(index)}
                className="bg-gray-500 text-white py-1 px-2 rounded hover:bg-gray-600"
              >
                Reset
              </button>
            </div>
            
            <button
              className="bg-red-500 text-white py-1 px-3 rounded-full hover:bg-red-600 transform hover:scale-105 transition duration-300 ease-in-out text-sm"
              onClick={() => deleteBox(index)}
            >
              Delete
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CampaignEditor;
